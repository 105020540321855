<template>
  <div>
    <div class="commen-hader">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h2>ENQUIRE</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="profile-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 col-sm-12">
            <div class="profile-edit">
              <h2><i class="fa fa-cogs"></i> ENQUIRE DETAILS</h2>

              <div class="upload-img">
                <div class="row middel-flex" v-if="enquire.auth_id">
                  <div class="col-sm-6">
                    <div>Product Name : {{ enquire.title }}</div>
                    <div>Product Price : {{ enquire.price }}</div>
                    <div>Product Quantity : {{ enquire.quenty }}</div>
                  </div>
                  <div class="col-sm-6">
                    <div>Subject : {{ enquire.subject }}</div>
                    <div>Message : {{ enquire.message }}</div>
                  </div>
                  <div class="col-12"><table class="table">
                      <tr><td>Id</td> <td>Enquiry Title</td><td>User ID</td> <td>Product Link</td><td>Action</td></tr>
                      <tr v-for=" enquir in enquire " :key="enquir.id"><td >{{enqui.title}}</td><td >{{enqui.role}}</td><td ></td></tr>
                  </table></div>
                  <span style="color:red" v-if="!enquire.reply"
                    >Please Wait Material inspector contact in some Time</span
                  >
                </div>
                <div class="row middel-flex" v-else>
                  No Any Enquire Details Found.
                </div>
              </div>
              <div class="col-8">
                  
              <!--    <div class="table-responsive">-->
              <!--        <div class="table table-hover"></div>-->
              <!--    </div>-->
              <!--  <div class="card card-default">-->
              <!--    <div class="card-header">Messages</div>-->
              <!--    <div class="card-body p-0">-->
              <!--      <ul-->
              <!--        class="list-unstyled"-->
              <!--        style="height:300px; overflow-y:scroll"-->
              <!--        VueChatScroll-->
              <!--      >-->
              <!--        <li-->
              <!--          class="p-2"-->
              <!--          v-for="(message, index) in messages"-->
              <!--          :key="index"-->
              <!--        >-->
              <!--          <strong> {{ message.user.name }} </strong>-->
              <!--          {{ message.message }}-->
              <!--          <input-->
              <!--            v-model="message.receiver_id"-->
              <!--            type="hidden"-->
              <!--            name="receiver_id"-->
              <!--            id="receiver_id"-->
              <!--            placeholder="Enter your message..."-->
              <!--            class="form-control"-->
              <!--          />-->
              <!--        </li>-->
              <!--      </ul>-->
              <!--    </div>-->

              <!--    <input-->
              <!--      @keyup.enter="sendMessage"-->
              <!--      v-model="newMessage"-->
              <!--      type="text"-->
              <!--      name="message"-->
              <!--      placeholder="Enter your message..."-->
              <!--      class="form-control"-->
              <!--      :disabled="!enquire.reply ? '' : disabled"-->
              <!--    />-->
              <!--  </div>-->
                <!-- <span class="text-muted">activeUser.name is typing...</span> -->
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="profile-edit-left">
              <h2><i class="fa fa-user"></i> ACCOUNT OVERVIEW</h2>
              <img :src="imgsrc + user.profile_img" />
              <h4>{{ user.name.toUpperCase() }}</h4>
              <p v-if="user.role_id == 1">Admin</p>
              <p v-if="user.role_id == 2">Buyer/Seller</p>
              <p v-if="user.role_id == 3">Material</p>
              <ul class="left-profile-link">
                <li></li>
                <li>
                  <router-link to="about"
                    ><i class="fa fa-user"></i>About Myself<span
                      >0</span
                    ></router-link
                  >
                  <!-- <a href="#"><i class="fa fa-user"></i> About me</a> -->
                </li>
                <li>
                  <router-link to="myproduct" v-if ="user.role_id != 3"
                    ><i class="fa fa-briefcase"></i>My Posts<span>{{
                      product
                    }}</span></router-link
                  >
                </li>
                <!-- <a href="#"><i class="fa fa-briefcase"></i> My Products <span>{{product}}</span></a></li> -->

                <li v-if="user.role_id == 2">
                  <router-link to="favourites"
                    ><i class="fa fa-heart"></i>My Favourites<span
                      >0</span
                    ></router-link
                  >
                  <!-- <a href="#"><i class="fa fa-heart"></i> Favourites <span>9</span></a> -->
                </li>
                <!-- <li><a href="#"><i class="fa fa-heart"></i> Favourites <span>9</span></a></li> -->

                <li v-if="user.role_id == 2">
                  <router-link to="reviews"
                    ><i class="fa fa-star"></i>My Reviews<span
                      >0</span
                    ></router-link
                  >
                  <!-- <a href="#"><i class="fa fa-star"></i> Reviews</a> -->
                </li>

                <li>
                  <router-link to="/dashboard"
                    ><i class="fa fa-cogs"></i> Profile Settings</router-link
                  >
                  <!-- <a href="#"><i class="fa fa-cogs"></i> Profile Settings</a> -->
                </li>
                <li>
                  <router-link to="/"
                    ><i class="fa fa-cogs"></i> My Enquires<span>{{enquire_count}}</span></router-link
                  >
                  <!-- <a href="#"><i class="fa fa-cogs"></i> Profile Settings</a> -->
                </li>

                <div v-if="user.role_id == 2">
                  <li>
                    <router-link to="pricing"
                      ><i class="fa fa-cogs"></i> My Plan
                      <span> Select Any Plans </span>
                    </router-link>
                  </li>
                </div>

                <div v-for="plan in plans" :key="plan.id">
                  <li>
                    <a href="#"
                      ><i class="fa fa-cogs"></i> My Plan
                      <span v-if="user.plans_id" style="color:green;">{{
                        plan.plans_name
                      }}</span>
                      <span v-else> No Select Any Plans </span>
                    </a>
                  </li>
                </div>

                <li>
                  <a href="#" @click="logout" style="color:red;"
                    ><i class="fa fa-power-off" style="color:red;"></i>
                    Logout</a
                  >
                </li>
              </ul>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>
<script>
import axios from "axios";
import { HTTP } from "../_helper/http-constants";
import VueChatScroll from "vue-chat-scroll";
import scrollTop from './utils/scrollTop'
import request from '../apis/request'
import { mapState, mapActions } from 'vuex'

//axios.defaults.withCredentials = true;

export default {
  name: "dashboard",
  components: {
    VueChatScroll,
  },

  data() {
    return {
      plans: "",
      product: "",
      enquire: "",
      ress: {},
      //email: "",
      //mobile: "",
      tagline: "",
      yourself: "",
      expertize: "",
      mobile1: "",
      website: "",
      old: "",
      password: "",
      password_confirmation: "",
      image: "",
      certificates: "",
      i: 0,
      enquire_count:"",
     
      messages: [],
      newMessage: "",
      receiver_id: "",
      sender_id: "",
      
      action: "/buyerupdate",

    //   imgsrc: "http://localhost/backend/public/admin_profile/",
    //   imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',
        imgsrc:axios.defaults.uploadURL,
    };
  },
  
  computed: {
      ...mapState('auth', {
          user: 'user'
      })
  },    

  methods: {
      ...mapActions('auth', {
          fetchUser: 'fetchUser',
          logoutUser: 'logout'
      }),
      sendMessage() {
      this.messages.push({
        // user: this.user,
        message: this.newMessage,
      });

      this.receiver_id = $("#receiver_id").val();

      const form = new FormData();
      form.append("message", this.newMessage);
      form.append("receiver_id", this.receiver_id);

      request.post("/messageSend", form).then(() => {
        this.newMessage = "";
        this.fetchMessages();
      });
    },
    fetchMessages() {
      request.get("/messageShow").then((response) => {
        this.messages = response.data;
      });
    },
    
    async logout() {
    //   HTTP.post("api/logout").then(() => {
    //     localStorage.removeItem("isLogged");
    //     window.localStorage.removeItem("isLogged");
    //     window.localStorage.removeItem("user");
    //     this.isLogged = false;
    //   });
        await this.logoutUser()
        this.$router.push({ name: "landing" });
    },

    previewFiles(event) {
      console.log(event);
      console.log(event.target.files[0]);

      this.image = event.target.files[0];
      // console.log(this.image)
    },

    previewFiless(event) {
      console.log(event);
      //console.log(event.target.files[0]);

      this.certificates = event.target.files[0];
    },

    update: function(e) {
      e.preventDefault();

      this.ress = {};

      const form = new FormData();
      form.append("tagline", this.tagline);
      form.append("yourself", this.yourself);
      form.append("expertize", this.expertize);
      form.append("mobile1", this.mobile1);
      form.append("website", this.website);
      form.append("old", this.old);
      form.append("password", this.password);
      form.append("password_confirmation", this.password_confirmation);
      form.append("image", this.image);
      form.append("certificates", this.certificates);

      //console.log(this.image);

      request
        .post(this.action, form)
        .then(async (res) => {
            await this.fetchUser()
            console.log(res);

          this.$toasted.success("Profile Updated successfully", {
            keepOnHover: true,
            iconPack: "fontawesome",
            icon: "check",
            theme: "toasted-primary",
            // timerProgressBar: true,
            duration: 3000,
          });

          //   alert('Profile Update successfully!');
        })
        .catch((res) => {
          //alert('err');
          if (res.response.status === 422) {
            this.ress = res.response.data.errors || {};
          }
          return res;
        });
    },

    validateEmail: function() {
      const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/;
      let res = regMail.test(this.email);
      let msg;
      if (res === false)
        msg =
          "<span class='warning' style='color: red;'>Email is not valid yet.</span>";
      else msg = "<span class='valid' style='color: green;'>Done!";
      document.getElementById("email-status").innerHTML = msg;
      return res;
    },

    validatemobile1: function() {
      let res = true;
      let digitCount = 0;
      for (let i = 0; i < this.mobile1.length; i++) {
        if (!(this.mobile1[i] >= "0" && this.mobile1[i] <= "9")) {
          res = false;
        } else {
          digitCount++;
        }
      }
      let msg;
      if (res == false)
        msg =
          "<span class='warning' style='color: red;'>Oops!!! Digits only.</span>";
      else if (digitCount != 10)
        msg =
          "<span class='warning' style='color: red;'>No!!! Just 10 digits</span>";
      else msg = "<span class='valid' style='color: green;'>Done!";
      document.getElementById("mobile-status").innerHTML = msg;
      return res && digitCount == 10;
    },
  },

  mounted() {
      scrollTop()

    request.get("/product-count").then((res) => {
        this.product = res.data.product_count;
         this.enquire_count = res.data.enquire_count;
        console.log(this.product);
    });

    request.get("/selectlans").then((res) => {
      this.plans = res.data;
    });

    request.get("/enquiredetailsmaterila").then((res) => {
      this.enquire = res.data;
    });
    
    this.fetchMessages();
    
  },
};
</script>

<style>
/*body{
  background-color:#f5f5f5;
}*/
.imagePreview {
  width: 100%;
  height: 180px;
  background-position: center center;
  background: url(http://cliquecities.com/assets/no-image-e3699ae23f866f6cbdf8ba2443ee5c4e.jpg);
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  box-shadow: 0px -3px 6px 2px rgba(0, 0, 0, 0.2);
}
/*.btn-primary
{
  display:block;
  border-radius:0px;
  box-shadow:0px 4px 6px 2px rgba(0,0,0,0.2);
  margin-top:-5px;
}*/
.imgUp {
  margin-bottom: 15px;
}
/*.del
{
  position:absolute;
  top:0px;
  right:15px;
  width:30px;
  height:30px;
  text-align:center;
  line-height:30px;
  background-color:rgba(255,255,255,0.6);
  cursor:pointer;
}*/
/*.imgAdd
{
  width:30px;
  height:30px;
  border-radius:50%;
  background-color:#4bd7ef;
  color:#fff;
  box-shadow:0px 0px 2px 1px rgba(0,0,0,0.2);
  text-align:center;
  line-height:30px;
  margin-top:0px;
  cursor:pointer;
  font-size:15px;
}*/
</style>
