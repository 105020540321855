import axios from "axios"

let baseURL

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // baseURL = "http://103.212.120.205:8080"
    baseURL = "https://mepcindia.com"
    // baseURL = "http://localhost:8000"
} else {
    // baseURL = "http://103.212.120.205:8080"
    baseURL = "https://mepcindia.com"
    // baseURL = "http://localhost:8000"
}

export const HTTP = axios.create({
    baseURL,
})
